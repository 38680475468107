import { render, staticRenderFns } from "./FooterInitial.vue?vue&type=template&id=f7649708&scoped=true&"
import script from "./FooterInitial.vue?vue&type=script&lang=js&"
export * from "./FooterInitial.vue?vue&type=script&lang=js&"
import style0 from "./FooterInitial.vue?vue&type=style&index=0&id=f7649708&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7649708",
  null
  
)

export default component.exports