<template>
  <section class="w-100 d-flex justify-content-center align-items-center footer-content bg-custom-blue p-3">
    <div class="realization">
      <b-img
        :src="logoSesi"
        alt="logo do realizador SESI - Serviço Social da Indústria"
      />
    </div>
  </section>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import logoSesi from '@/assets/custom-images/logo-sesi.png'

export default {
  components: {
    BImg,
  },

  data() {
    return {
      logoSesi,
    }
  },

  mounted() {
    this.$helpers.indexablePage()
  },
}
</script>

<style scoped>
.row-100-vh {
    height: 100vh;
}
.footer-content {
    margin-top: 4rem;
}

.realization img {
  max-height: 50px;
}

.overflow-initial-content {
    overflow: hidden;
}

@media only screen and (max-width: 1600px) {
    .overflow-initial-content {
        overflow: auto;
    }
}
@media only screen and (max-height: 940px) {
    .footer-content {
        margin-top: 2rem;
    }
}

@media only screen and (max-height: 870px) {
    .footer-content {
        margin-top: 1rem;
    }
}

</style>
