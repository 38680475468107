<template>
    <b-nav class="d-flex align-items-center mb-4">
        <b-nav-item :to="{ name: 'start-page' }" class="mr-md-auto mb-1 mr-5"><b-img center :src="vacinaSesiLogo" alt="Logo do VacinaSESI"></b-img></b-nav-item>
        <div v-if="!onlyLogo" class="mr-auto d-md-flex mr-md-0">
            <b-nav-item :to="{ name: 'auth-login' }" class="menu-item mx-md-2">CADASTRAR | ACESSAR</b-nav-item>
            <b-nav-item :to="{ name: 'campaign-calendar' }" class="menu-item mx-md-2">VER CAMPANHAS DO MEU ESTADO</b-nav-item>
            <b-nav-item :to="{ name: 'contact'}" class="menu-item mx-md-2">CONTATO</b-nav-item>
        </div>
    </b-nav>
</template>

<script>
import vacinaSesiLogo from '@/assets/custom-images/vacina-sesi-logo-azul.svg';
import { BButton, BRow, BCol, BImg, BNav, BNavItem} from 'bootstrap-vue';

export default {
    props: {
        onlyLogo: {
            type: Boolean,
        },
    },
    components: {
    BButton,
    BRow,
    BCol,
    BImg,
    BNav, 
    BNavItem
},

data() {
    return {
        vacinaSesiLogo
    }
}
}
</script>

<style scoped>
.menu-item a{
   color: #6E6B7B;
   font-weight: 500;
}

.nav-item a{
   padding: 0;
}
</style>
